import React, { useMemo } from "react"
import Layout from "./../../components/layout"
import HeaderPages from "../../components/header-pages"
import { useLocation } from "@reach/router"
import { Container } from "react-bootstrap"
import * as HeroIcons from 'react-icons/hi'
import { navigate } from "gatsby"


const HelpInformationDetail = () => {
    const location = useLocation()
    const data = useMemo(() => { return location?.state?.body }, [location])

    return (
        <Layout pageTitle="Informasi Bantuan | Klabbelanja">
            <HeaderPages />
            <div style={{ height: 150 }} />
            <Container>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <HeroIcons.HiArrowLeft size={30} color='black' style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                    <div style={{ width: 50 }} />
                    <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: location?.state?.title }} />
                </div>
                <div style={{ height: 50 }} />
                <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: data }} />
            </Container>
            {/* <DataTermCondition /> */}
            {/* <Footer /> */}
        </Layout>
    )
}

export default HelpInformationDetail
